<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="emailPaymentAdviceDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          {{ this.model.name.singular }}
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to record Email Payment Advice to Client
          date
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalEmailPaymentAdviceSubmit()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="emailPaymentAdviceDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span v-if="isTaxpodAdminRoles && !btnDisabled && !editMode">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="emailPaymentAdviceDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-email-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>Email Payment Advice to Client</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <div>
        <v-col cols="12" sm="12" md="12" v-if="cnCheckDisplay">
          <v-alert
            :type="cnCheckStatus.cnAlertType"
            :color="cnCheckStatus.color"
          >
            {{ cnCheckStatus.cnCheckMsg }}
          </v-alert>
        </v-col>

        <v-col class="d-flex" cols="12" sm="12" md="6">
          <v-text-field
            dense
            filled
            v-model="formData.emailSubject"
            :error-messages="errorField.emailSubject"
            label="Email Subject"
            readonly
          ></v-text-field>
        </v-col>

        <!--Refund Payment Advice-->
        <v-col class="d-flex" cols="6" lg="6" sm="6" md="6">
          <v-col cols="9" class="pa-0">
            <div
              v-cloak
              @drop.prevent="addDropFile($event, 'refundPaymentAdvice')"
              @dragover.prevent
              class="w-100"
            >
              <v-file-input
                :prepend-icon="null"
                prepend-inner-icon="mdi-paperclip"
                multiple
                dense
                filled
                small-chips
                label="Refund Payment Advice"
                accept=".pdf"
                v-model="formData.refundPaymentAdvice"
                :error-messages="errorField.refundPaymentAdvice"
                :truncate-length="1000"
                @change="scanPdf($event)"
              ></v-file-input>
            </div>
          </v-col>
          <v-col class="pt-0" cols="3">
            <v-btn
              x-large
              :color="HRDC_BLUE_THEME_COLOR"
              outlined
              @click="previewAutoEmbedPdf('refundPaymentAdvice')"
            >
              Preview
            </v-btn>
          </v-col>
        </v-col>

        <!--Credit Note-->
        <v-col class="d-flex pa-0" cols="6" lg="6" sm="6" md="6">
          <v-col class="d-flex" cols="9">
            <div
              v-cloak
              @drop.prevent="addDropFile($event, 'creditNote')"
              @dragover.prevent
              class="w-100"
            >
              <v-file-input
                :prepend-icon="null"
                prepend-inner-icon="mdi-paperclip"
                dense
                filled
                small-chips
                multiple
                label="Credit Note"
                accept=".pdf"
                v-model="formData.creditNote"
                :error-messages="errorField.creditNote"
                :truncate-length="1000"
                disabled
              ></v-file-input>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              dense
              filled
              v-model="formData.creditNoteNo"
              :error-messages="errorField.creditNoteNo"
              label="CN No."
              disabled
            ></v-text-field>
          </v-col>
        </v-col>

        <!--To Recipient -->
        <v-col class="d-flex" cols="12" sm="12" md="6">
          <v-combobox
            dense
            filled
            label="TO Recipient"
            v-model="formData.toEmail"
            :error-messages="errorField.toEmail"
            :hide-selected="true"
            multiple
            small-chips
            :readonly="editMode"
          >
            <template v-slot:selection="data">
              <v-chip
                small
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                @click:close="data.parent.selectItem(data.item)"
              >
                {{ data.item }}
                <v-icon small @click="data.parent.selectItem(data.item)">
                  $delete
                </v-icon>
              </v-chip>
            </template>

            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-information </v-icon>
                </template>
                Press <kbd>enter/tab</kbd> to insert multiple email
              </v-tooltip>
            </template></v-combobox
          >
        </v-col>

        <!--CC Recipient-->
        <v-col class="d-flex" cols="12" sm="12" md="6">
          <v-combobox
            dense
            filled
            label="CC Recipient"
            v-model="formData.ccEmail"
            :error-messages="errorField.ccEmail"
            :hide-selected="true"
            multiple
            small-chips
            :readonly="editMode"
          >
            <template v-slot:selection="data">
              <v-chip
                small
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                @click:close="data.parent.selectItem(data.item)"
              >
                {{ data.item }}
                <v-icon small @click="data.parent.selectItem(data.item)">
                  $delete
                </v-icon>
              </v-chip>
            </template>

            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-information </v-icon>
                </template>
                Press <kbd>enter/tab</kbd> to insert multiple email
              </v-tooltip>
            </template></v-combobox
          >
        </v-col>

        <!-- Email Content -->
        <v-col class="" cols="12" sm="12" md="6">
          <div>
            <!-- Use the component in the right place of the template -->
            <tiptap-vuetify
              v-model="formData.emailContent"
              :extensions="extensions"
            />
          </div>
          <span class="text-danger">{{
            errorField.emailContent ? errorField.emailContent[0] : null
          }}</span>
        </v-col>

        <!-- Email Payment Advice to Client Date -->
        <v-col cols="12" sm="6" md="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                v-model="formData.emailPaymentAdviceToClientDate"
                label="Email Payment Advice to Client Date (YYYY-MM-DD)"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.emailPaymentAdviceToClientDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tax_summit/objects/globalData";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  mixins: [hrdcFunction],
  components: {
    TiptapVuetify,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    cnCheckStatus() {
      let color = "red lighten-2";
      let cnCheckMsg =
        "CN No. is not same as system CN No, kindly double check before proceed";
      let cnAlertType = "error";

      if (this.cnCheckResult) {
        color = "green lighten-2";
        cnCheckMsg = "Payment Advice CN No. checked pass";
        cnAlertType = "success";
      }

      return {
        color,
        cnCheckMsg,
        cnAlertType,
      };
    },
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiScanPdfText: new Api(),
    apiSubmit: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {
      applicationNo: null,
      emailPaymentAdviceToClientDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      refundPaymentAdvice: [],
      creditNote: [],
      toEmail: [],
      ccEmail: [],
      toEmailUuid: [],
      ccEmailUuid: [],
      emailSubject: null,
      emailContent: `Hi,<br><br>Good day to you, enclosed herewith the Credit Note and Refund Payment Advice for your records.`,
      creditNoteNo: null,
    },
    errorField: {
      applicationNo: null,
      refundPaymentAdvice: null,
      creditNote: null,
      emailContent: null,
      toEmail: null,
      ccEmail: null,
      emailSubject: null,
      emailPaymentAdviceToClientDate: null,
      creditNoteNo: null,
    },
    loadingDialog: false,
    emailPaymentAdviceDialog: false,
    editMode: false,
    btnDisabled: false,
    cnCheckResult: false,
    cnCheckDisplay: false,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  async created() {
    this.showLoadingDialog();
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Events Assignation.
    this.serviceDataAssignation(this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/email_payment_advice/create-email-payment-advice`,
        method: "post",
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiScanPdfText: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/email_payment_advice/scan-pdf`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[this.$store.state.hrdc_tax_summit.data.stageId];
    this.rolesAssignation(this.auth.roles);
    if (!this.isTaxpodAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.checkFormAlreadyFilledGte(
      currentStageId,
      this.hrdcData.stageSlug.emailPaymentAdviceToClient
    );

    this.checkFormNotYetFilledLesser(
      currentStageId,
      this.hrdcData.stageSlug.financeRefundToClient
    );

    if (this.editMode) {
      this.apiGetApplicationSingleDetail.fetch();
      this.apiGetApplicationSingleDetail.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;
          if (status) {
            const emailPaymentAdviceStageId =
              this.hrdcData.stagePriority.emailPaymentAdviceToClient;
            this.formData.emailPaymentAdviceToClientDate = moment(
              data.ApplicationLog.find(
                (obj) => obj.stageId == emailPaymentAdviceStageId
              ).startDate
            ).format("YYYY-MM-DD");
            this.formData.creditNoteNo = data.creditNoteNo;
          }

          this.hideLoadingDialog();
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      this.apiGetApplicationSingleDetail.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;
          if (status) {
            this.formData.emailSubject = `Credit Note and Refund Payment Advice For YYC ${
              this.name3
            } - ${data.oriCompanyName.toUpperCase()}`;

            // Get recipient email to (company client email).
            const companyPicEmail = data.ApplicationPicDetail.map(
              (item) => item.picEmail
            );

            this.formData.toEmail = companyPicEmail;
            this.formData.ccEmail.push(data.referByEmail);
            this.formData.creditNoteNo = data.creditNoteNo;

            // Get multiple CN object.
            const creditNoteObj = data.ApplicationAttachment.filter(
              (item) =>
                item.stageId == this.hrdcData.stagePriority.creditNoteGenerated
            );

            // Assign attachment.
            for (const item of creditNoteObj) {
              // Create a Blob object from the data
              const blob = new Blob([new Uint8Array(item.dataArray)]);

              // Create the File object
              const file = new File([blob], item.fileName, {
                type: "application/pdf",
              });

              switch (item.type) {
                case "creditNote":
                  this.formData.creditNote.push(file);
                  break;
              }
            }
          }
        } catch (err) {
          console.log(err);
        }

        this.hideLoadingDialog();
      });
      this.apiGetApplicationSingleDetail.fetch();
    }
  },
  methods: {
    clearErrorMsg(field = null) {
      if (!field) {
        for (let error in this.errorField) {
          this.errorField[error] = null;
        }
      } else {
        this.errorField[field] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    modalEmailPaymentAdviceSubmit() {
      this.emailPaymentAdviceDialog = !this.emailPaymentAdviceDialog;
      this.submit();
    },
    addDropFile(e, key) {
      let file = e.dataTransfer.files;

      for (const fileItem of file) {
        if (!fileItem.type.match("pdf.*")) {
          this.errorField.refundPaymentAdvice = "Please select pdf file format";
          return;
        }

        this.formData.refundPaymentAdvice.push(fileItem);
      }

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    previewAutoEmbedPdf(fileType) {
      if (this.formData[fileType].length == 0) {
        this.$store.dispatch("showMessage", {
          message: "No Preview Available",
          messageType: "info",
        });
        return;
      }

      for (let i = 0; i < this.formData.refundPaymentAdvice.length; i++) {
        let url = URL.createObjectURL(this.formData[fileType][i]);
        window.open(url, "_blank");
      }
    },
    scanPdf($event) {
      if ($event.length > 0) {
        this.showLoadingDialog();
        const fd = new FormData();
        fd.append("refundPaymentAdvice", this.formData.refundPaymentAdvice[0]); // Only function when payment advice got 1, else need to manual check for extra Payment Advice.

        this.apiScanPdfText.setParams(fd);
        this.apiScanPdfText.setCallbackCompleted((response) => {
          try {
            if (response.status) {
              const cnNo = response.data;
              this.cnCheckDisplay = true;
              if (cnNo !== this.formData.creditNoteNo) {
                this.cnCheckResult = false;
              }

              if (cnNo == this.formData.creditNoteNo) {
                this.cnCheckResult = true;
              }
            } else {
              this.$store.dispatch("showMessage", {
                message: "Please upload valid Payment Advice",
                messageType: "error",
                timeout: 2000,
              });
            }
            this.hideLoadingDialog();
          } catch (err) {
            console.log(err);
          }
        });
        this.apiScanPdfText.fetch();
      } else {
        this.cnCheckDisplay = false;
      }
    },
    redirectBack() {
      let route = "HrdcTaxSummitApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc_tax_summit.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    async submit() {
      this.showLoadingDialog();
      // Generate Form Data to handle multipart/form-data
      const fd = new FormData();
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("toEmail", this.formData.toEmail);
      fd.append("ccEmail", this.formData.ccEmail);
      fd.append("emailSubject", this.formData.emailSubject);
      fd.append(
        "emailPaymentAdviceToClientDate",
        this.formData.emailPaymentAdviceToClientDate
      );
      fd.append("referById", this.auth.uuid);
      fd.append("editMode", this.editMode);
      fd.append("emailContent", this.formData.emailContent);

      // Loop multiple files upload.
      for (let i = 0; i < this.formData.refundPaymentAdvice.length; i++) {
        fd.append("refundPaymentAdvice", this.formData.refundPaymentAdvice[i]);
      }

      if (this.formData.refundPaymentAdvice) {
        for (let i = 0; i < this.formData.refundPaymentAdvice.length; i++) {
          fd.append(
            "refundPaymentAdviceFileName",
            this.formData.refundPaymentAdvice[i].name
          );
        }
      }

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.hideLoadingDialog();

          if (!response.status) {
            this.showErrorMessage(response);
            if (response.message) {
              this.$store.dispatch("showMessage", {
                message: response.message,
                messageType: "error",
                timeout: 2000,
              });
            }
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcTaxSummitApplication",
              query: {
                viewId: this.$store.state.hrdc_tax_summit.data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>
